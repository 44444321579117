import {
	Box,
	Tabs,
	Tab,
	TextField,
	FormControlLabel,
	Switch,
	FormControl,
	InputLabel,
	Select,
	MenuItem
} from '@mui/material';
import { generateClient } from 'aws-amplify/api';
import { Formik } from 'formik';
import { useContext, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ActivityLog } from 'src/components/contacts/activityLog';
import { PageAppBar } from 'src/components/pageAppBar';
import { UserContext } from 'src/contexts/UserContext';
import { getContact } from 'src/graphql/queries';
import { tabStyle } from 'src/theme';
import phone from 'phone';
import moment from 'moment';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { updateContact } from 'src/graphql/mutations';
import { contactValidation } from 'src/components/yupValidations';
import { enqueueSnackbar } from 'notistack';
import { US } from 'country-region-data';
import { IS_PROD } from '../../models/globals';
import { customFieldTypes } from '../../models/customFieldsTypes';

export function Contact() {
	const usStates = US[2];
	const navigate = useNavigate();
	const client = generateClient();
	const userContext = useContext(UserContext);
	const [contact, setContact] = useState({
		firstName: '',
		lastName: '',
		phone: '',
		cell: '',
		email: '',
		source: '',
		address1: '',
		address2: '',
		city: '',
		state: '',
		zip: '',
		externalId: '',
		customFields: {},
	});
	const { id } = useParams();
	const [currentTab, setCurrentTab] = useState(0);

	useEffect(() => {
		async function getData() {
			const contact = await client.graphql({
				query: getContact,
				variables: { id: id },
			});

			if (contact.data.getContact) {
				const customFields = JSON.parse(contact.data.getContact.customFields);
				contact.data.getContact.customFields = customFields;
				setContact(contact.data.getContact);
			}
		}

		if (userContext.tenantId && id) {
			getData();
		}
	}, [userContext.tenantId, id]);

	return (
		<Box>
			<Formik
				enableReinitialize
				initialValues={contact}
				validationSchema={contactValidation(userContext.customFields)}
				onSubmit={async (values, formikBag) => {
					try {
						const contact = { ...values };

						if (contact.customFields) {
							for (const key in contact.customFields) {
								const tenantCustomField = userContext.customFields.find((field) => field.name === key);
								if (tenantCustomField) {
									if (tenantCustomField.type === 'Number') {
										contact.customFields[key] = +contact.customFields[key];
									} else if (tenantCustomField.type === 'DateTime') {
										const dateField = contact.customFields[key];
										if (dateField && Number(dateField)) {
											contact.customFields[key] = dateField;
										} else {
											contact.customFields[key] = moment(dateField, 'YYYY-MM-DDTHH:mm').format('x');
										}
									} else if (tenantCustomField.type === 'Boolean') {
										contact.customFields[key] = Boolean(contact.customFields[key]);
									} else if (tenantCustomField.type === customFieldTypes.Phone) {
										contact.customFields[key] = phone(contact.customFields[key], { validateMobilePrefix: IS_PROD }).phoneNumber;
									}
								}
							}
							contact.customFields = JSON.stringify(contact.customFields);
						}

						for (const key in contact) {
							if (contact[key] === '') {
								contact[key] = null;
							}
						}

						if (contact.phone) {
							contact.phone = phone(contact.phone, { validateMobilePrefix: IS_PROD }).phoneNumber;
						}
						if (contact.cell) {
							contact.cell = phone(contact.cell, { validateMobilePrefix: IS_PROD }).phoneNumber;
						}

						contact.updatedBy = (await fetchUserAttributes()).name;
						delete contact.createdAt;
						delete contact.updatedAt;
						const response = await client.graphql({
							query: updateContact,
							variables: { input: contact },
						});
						const updatedContact = response.data.updateContact;
						if (updatedContact.customFields) {
							updatedContact.customFields = JSON.parse(updatedContact.customFields);
						}

						formikBag.setSubmitting(false);
						setContact(updatedContact);
						formikBag.resetForm(updatedContact);
						enqueueSnackbar('Contact updated successfully', { variant: 'success' });

					} catch (err) {
						console.error(err);
						enqueueSnackbar('Error updating contact', { variant: 'error' });
					}
				}}
			>
				{(props) => (
					<form onSubmit={props.handleSubmit}>
						<PageAppBar
							title={`${contact.firstName} ${contact.lastName}`}
							description={`External ID: ${contact.externalId}`}
							actionOneText="Save"
							actionOneHandler={props.handleSubmit}
							actionTwoText="Cancel"
							actionTwoHandler={() => navigate('/contacts')}
						/>




						<Box display="flex" flexDirection="column" gap={2}>
							<Tabs
								value={currentTab}
								onChange={(event, newValue) => setCurrentTab(newValue)}
								textColor="primary"
								indicatorColor="primary"
							>
								<Tab sx={tabStyle} label="Contact Information" />
								<Tab sx={tabStyle} label="Custom Fields" />
								<Tab sx={tabStyle} label="Activity Log" />
							</Tabs>
							{currentTab === 0 && (
								<Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
									<TextField
										label="Phone"
										value={props.values.phone ? props.values.phone : ''}
										onChange={props.handleChange}
										error={props.errors?.phone && props.touched?.phone}
										helperText={props.errors?.phone && props.touched?.phone && props.errors.phone}
										name="phone"
									/>
									<TextField
										label="Cell"
										value={props.values.cell ? props.values.cell : ''}
										onChange={props.handleChange}
										error={props.errors?.cell && props.touched?.cell}
										helperText={props.errors?.cell && props.touched?.cell && props.errors.cell}
										name="cell"
									/>
									<TextField
										label="Email"
										value={props.values.email ? props.values.email : ''}
										onChange={props.handleChange}
										error={props.errors?.email && props.touched?.email}
										helperText={props.errors?.email && props.touched?.email && props.errors.email}
										name="email"
									/>
									<TextField
										label="Source"
										value={props.values.source ? props.values.source : ''}
										onChange={props.handleChange}
										error={props.errors?.source && props.touched?.source}
										helperText={props.errors?.source && props.touched?.source && props.errors.source}
										name="source"
									/>
									<TextField
										label="Address 1"
										value={props.values.address1 ? props.values.address1 : ''}
										onChange={props.handleChange}
										error={props.errors?.address1 && props.touched?.address1}
										helperText={props.errors?.address1 && props.touched?.address1 && props.errors.address1}
										name='address1'
									/>
									<TextField
										label="Address 2"
										value={props.values.address2 ? props.values.address2 : ''}
										onChange={props.handleChange}
										error={props.errors?.address2 && props.touched?.address2}
										helperText={props.errors?.address2 && props.touched?.address2 && props.errors.address2}
										name='address2'
									/>
									<TextField
										label="City"
										value={props.values.city ? props.values.city : ''}
										onChange={props.handleChange}
										error={props.errors?.city && props.touched?.city}
										helperText={props.errors?.city && props.touched?.city && props.errors.city}
										name="city"
									/>
									<FormControl>
										<InputLabel error={props.errors?.state && props.touched?.state}>State</InputLabel>
										<Select
											name="state"
											label="State"
											onChange={props.handleChange}
											onBlur={props.handleBlur}
											value={props.values.state ? props.values.state : ''}
											error={props.errors?.state && props.touched?.state}
										>
											{usStates.map(state =>
												<MenuItem key={state[1]} value={state[1]}>{state[0]}</MenuItem>)}
										</Select>
										{props.errors?.state && props.touched?.state &&
											<FormHelperText error>{props.errors.state}</FormHelperText>}
									</FormControl>
									<TextField
										label="Zip"
										value={props.values.zip ? props.values.zip : ''}
										onChange={props.handleChange}
										name="zip"
										error={props.errors?.zip && props.touched?.zip}
										helperText={props.errors?.zip && props.touched?.zip && props.errors.zip}
									/>
								</Box>
							)}
							{currentTab === 1 && (
								<Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
									{userContext.customFields.map((field) =>
											(function () {
												switch (field.type) {
													case 'Number':
													case 'String':
													case 'Phone':
														return (
															<TextField
																key={field.name}
																name={`customFields.${field.name}`}
																label={field.displayName}
																value={props.values.customFields && props.values.customFields[field.name] ? props.values.customFields[field.name] : ''}
																error={props.errors?.customFields && props.errors.customFields[field.name] && props.touched.customFields && props.touched.customFields[field.name]}
																helperText={props.errors?.customFields && props.errors.customFields[field.name] && props.touched.customFields && props.touched.customFields[field.name] && props.errors.customFields[field.name]}
																onChange={props.handleChange}
															/>
														);
													case 'Boolean':
														return (
															<FormControlLabel
																key={field.name}
																control={
																	<Switch
																		name={`customFields.${field.name}`}
																		checked={props.values.customFields && props.values.customFields[field.name]}
																		onChange={props.handleChange}
																	/>
																}
																label={field.displayName}
															/>
														);
													case 'DateTime':
														return (
															<TextField
																key={field.name}
																name={`customFields.${field.name}`}
																label={field.displayName}
																value={props.values.customFields && props.values.customFields[field.name] ? props.values.customFields[field.name] : ''}
																error={props.errors?.customFields && props.errors.customFields[field.name] && props.touched.customFields && props.touched.customFields[field.name]}
																helperText={props.errors?.customFields && props.errors.customFields[field.name] && props.touched.customFields && props.touched.customFields[field.name] && props.errors.customFields[field.name]}
																onChange={props.handleChange}
																type="date"
															/>
														);
													default:
														return null;
												}
											})(),
										)}

								</Box>)}

							{currentTab === 2 && (
								<Box>
									<ActivityLog contactId={id} />
								</Box>
							)}
						</Box>
					</form>
				)}
			</Formik>

		</Box>
	);
}
