import { useEffect, useState, useContext } from 'react';
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Button,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Box,
	CircularProgress,
} from '@mui/material';
import { UserContext } from '../../contexts/UserContext';
import { enqueueSnackbar } from 'notistack';
import { generateClient } from 'aws-amplify/api';
import { updateTenantSettings } from '../../graphql/mutations';
import { Webex } from 'src/components/connection/webex';
import { PageAppBar } from 'src/components/pageAppBar';
import { cardStyle } from 'src/theme';
import { NICE } from 'src/components/connection/nice';
import { RingCX } from 'src/components/connection/ringcx';
import AmazonConnect from 'src/components/connection/amazon-connect';

export function ConnectionDetails() {
	const client = generateClient();
	const [telephonyProvider, setTelephony] = useState('None');
	const userContext = useContext(UserContext);

	const [saving, setSaving] = useState(false);

	document.title = 'Connection Details';

	useEffect(() => {
		if (userContext.telephonyProvider) {
			setTelephony(userContext.telephonyProvider);
		}
	}, [userContext]);

	const changeSelectedTelephonyProvider = (event) => {
		setTelephony(event.target.value);
		if (event.target.value !== 'None') {
			userContext.telephonyProvider = event.target.value;
		}
	};

	const saveNone = async () => {
		setSaving(true);
		try {
			userContext.telephonyProvider = 'None';
			const config = {
				id: userContext.tenantId,
				telephonyProvider: 'None',
				telephonyProviderInfo: {
					amazonConnect: null,
					incontact: null,
					nobelbiz: null,
					genesys: null,
					webex: null,
				}
			};
			await client.graphql({
				query: updateTenantSettings,
				variables: { input: config },
			});
			setSaving(false);
			enqueueSnackbar('Saved successfully.', {
				variant: 'success',
				autoHideDuration: 5000,
			});
		} catch (error) {
			console.error('Error saving telephony provider:', error);
			setSaving(false);
			enqueueSnackbar('Save failed.', {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};

	return (
		<div style={{ margin: 'auto', maxWidth: '1800px' }}>
			<PageAppBar
				title="Connection Details"
				description="Set up and configure settings relating to your telephony provider"
			/>

			<Card
				style={cardStyle}
				elevation={0}
				sx={{ marginBottom: '24px' }}
			>
				<CardHeader title="Telephony Provider" />
				<CardContent>
					<FormControl fullWidth>
						<InputLabel id="telephony-provider-label" color="primary" htmlFor="telephony-provider">Provider</InputLabel>
						<Select
							value={telephonyProvider}
							inputProps={{ id: 'telephony-provider' }}
							label="Provider"
							labelId="telephony-provider-label"
							color="primary"
							onChange={changeSelectedTelephonyProvider}
						>
							<MenuItem value="None">None</MenuItem>
							<MenuItem value="NiC">NICE CXone</MenuItem>
							<MenuItem value="Webex">Webex Contact Center</MenuItem>
							<MenuItem value="RingCX">RingCX</MenuItem>
							<MenuItem value="AmazonConnect">Amazon Connect</MenuItem>
						</Select>
					</FormControl>
				</CardContent>

				{telephonyProvider === 'None' && (
					<CardActions>
						<Box
							width="100%"
							display="flex"
							flexDirection="row"
							justifyContent="end"
						>
							{(saving) &&
								<CircularProgress variant="indeterminate" color="primary" size="25px" />
							}
							<Button
								onClick={saveNone}
								type="submit"
								color="primary"
								disabled={saving}
							>
								{saving ? 'Saving...' : 'Save'}
							</Button>
						</Box>
					</CardActions>
				)}
			</Card>


			{telephonyProvider === 'NiC' && (
				<NICE />
			)}
			{telephonyProvider === 'Webex' &&
				<Webex />
			}
			{telephonyProvider === 'RingCX' && (
				<RingCX />
			)}
			{telephonyProvider === 'AmazonConnect' && (
				<AmazonConnect />
			)}
		</div>
	);
}
