import { get, post } from 'aws-amplify/api'

export class RingCX {
	static async getRingCampaigns(token, apiKey) {
		try {
			const campaigns = await get({
				apiName: 'cdyxoutreach',
				path: '/cci/ringcx/campaigns',
				options : {
					headers: {
						Authorization: `Bearer ${token}`,
						'x-api-key': apiKey
					}
				}
			}).response;

			return await campaigns.body.json();

		} catch (error) {
			console.log('Error getting RingCX campaigns', error);
		}
	}

	static async getCampaignDispositions(token, apiKey, campaignId, dialGroupId) {
		try {
			const dispositions = await post({
				apiName: 'cdyxoutreach',
				path: '/cci/ringcx/dispositions',
				options : {
					headers: {
						Authorization: `Bearer ${token}`,
						'x-api-key': apiKey
					},
					body: {
						campaignId,
						dialGroupId
					}
				}
			}).response;

			return await dispositions.body.json();

		} catch (error) {
			console.log('Error getting RingCX dispositions', error);
		}
	}
}
