import { useContext, useEffect, useState } from "react";
import { UserContext } from "src/contexts/UserContext";
import { Formik } from "formik";
import { enqueueSnackbar } from "notistack";
import { Box, Button, Card, CardContent, CardHeader, Grid, TextField, Select, MenuItem, InputLabel, CardActions, FormControl, Typography, CircularProgress } from "@mui/material";
import { generateClient, get, post } from "aws-amplify/api";
import React from "react";
import { object, string } from 'yup';
import { getTenantSettings } from "src/graphql/queries";
import { actionOneButtonStyle, actionTwoButtonStyle } from "src/theme";

const REGIONS = {
	usEast: 'us-east-1',
	usWest: 'us-west-2',
};

export default function AmazonConnect() {
	const client = generateClient();
	const userContext = useContext(UserContext);

	const [amazonConnectSettings, setAmazonConnectSettings] = useState({
		region: 'us-west-2',
		instanceId: '',
		accessKey: '',
		secretKey: '',
		transferFlowId: '',
		// queueId: '',
	});

	const [flows, setFlows] = useState([]);
	// const [queues, setQueues] = useState([]);
	// const [wrapUpCodes, setWrapUpCodes] = useState([]);

	const [testingConnection, setTestingConnection] = useState(false);
	const [connectionSucceeded, setConnectionSucceeded] = useState(false);
	const [savingValues, setSavingValues] = useState(false);

	// Loads the saved values when the component is first rendered.
	useEffect(() => {
		async function getData() {
			try {
				const graphqlResponse = await client.graphql({
					query: getTenantSettings,
					variables: { id: userContext.tenantId }
				});
				const tenantSettings = graphqlResponse.data.getTenantSettings;
				if (tenantSettings.telephoneProviderInfo && tenantSettings.telephoneProviderInfo.amazonConnect) {
					setAmazonConnectSettings(tenantSettings.telephoneProviderInfo.amazonConnect);

					// Get Amazon Connect options.
					if (tenantSettings.telephoneProviderInfo.amazonConnect) {
						// await getQueues();
						await getFlows();
					}
				}
			} catch (err) {
				console.error('Error loading Amazon Connect tenant settings', err);
			}
		}
		if (userContext.telephonyProvider === 'AmazonConnect') {
			getData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userContext.telephonyProvider]);

	/**
	 * Tests the connection to Amazon Connect.
	 *
	 * @param {object} authSettings The Amazon Connect authentication settings.
	 */
	async function testConnection(authSettings) {
		setTestingConnection(true);
		try {
			const test = await post({
				apiName: 'cdyxoutreach',
				path: '/cci/amazon-connect/test-connection',
				options: {
					headers: {
						Authorization: `Bearer ${userContext.cognitoToken}`,
						'x-api-key': userContext.apiKey,
						'Content-Type': 'application/json',
					},
					body: {
						...authSettings,
					}
				}
			}).response;

			const testResponse = await test.body.json();

			setFlows(testResponse.flows);
			// setQueues(testResponse.queues);
			setConnectionSucceeded(true);
			enqueueSnackbar('Successfully authenticated with Amazon Connect. Please provide and save your configuration values.', {
				variant: 'success',
				autoHideDuration: 5000,
			});
		} catch (connectionTestError) {
			console.error('Error authenticating with Amazon Connect:', connectionTestError);
			setConnectionSucceeded(false);
			enqueueSnackbar('Unable to authenticate with Amazon Connect. Please check your connection settings.', {
				variant: 'error',
				autoHideDuration: 5000
			});
		}
		setTestingConnection(false);
	}

	/**
	 * Saves the entered values for Amazon Connect.
	 *
	 * @param {object} amazonConnectSettings The Amazon Connect values.
	 */
	async function saveAmazonConnectValues(amazonConnectSettings) {
		setSavingValues(true);
		try {
			await post({
				apiName: 'cdyxoutreach',
				path: '/cci/amazon-connect/save-connection',
				options: {
					headers: {
						Authorization: `Bearer ${userContext.cognitoToken}`,
						'x-api-key': userContext.apiKey,
						'Content-Type': 'application/json',
					},
					body: {
						amazonConnectSettings,
					}
				}
			}).response;
			enqueueSnackbar('Your Amazon Connect settings have been saved.', {
				variant: 'success',
				autoHideDuration: 5000,
			});
		} catch (error) {
			enqueueSnackbar('An unexpected error occurred while saving your Amazon Connect settings. Please make a note of your values and try again later.', {
				variant: 'error',
				autoHideDuration: 5000
			});
		}
		setSavingValues(false);
	}

	/**
	 * Gets a list of flows for the tenant.
	 */
	async function getFlows() {
		const flows = await get({
			apiName: 'cdyxoutreach',
			path: '/cci/amazon-connect/flows',
			options: {
				headers: {
					Authorization: `Bearer ${userContext.cognitoToken}`,
					'x-api-key': userContext.apiKey,
					'Content-Type': 'application/json',
				}

			}
		}).response;
		setFlows(flows.body.json());
	}

	return (
		<Box>
			<Formik
				initialValues={amazonConnectSettings}
				enableReinitialize={true}
				onSubmit={values => {
					saveAmazonConnectValues(values);
				}}
				validationSchema={object({
					region: string().required('Required'),
					instanceId: string().required('Required'),
					accessKey: string().required('Required'),
					secretKey: string().required('Required'),
					transferFlowId: string().required('Required'),
					queueId: string().required('Required'),
				})}
			>
				{formikProps => (
					<form key="amazonConnect" onSubmit={formikProps.handleSubmit}>
						<Card variant="outlined">
							<CardHeader title='Amazon Connect' titleTypographyProps={{ variant: 'h6' }} />

							<CardContent>
								<Box display="flex" flexDirection="column" gap={2}>
									<Typography variant="body2" gutterBottom>Voice channel settings required for outbound calls.</Typography>

									<Typography variant="overline">Authentication</Typography>
									<Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr" gap={2}>
										<FormControl required>
											<InputLabel color="primary">Region</InputLabel>
											<Select color="primary"
												label="Region"
												name="region"
												onBlur={formikProps.handleBlur}
												value={formikProps.values.region}
												onChange={formikProps.handleChange}
											>
												{Object.keys(REGIONS).map((regionKey) => (
													<MenuItem key={regionKey} value={REGIONS[regionKey]}>{REGIONS[regionKey]}</MenuItem>
												))}
											</Select>
										</FormControl>
										<TextField
											color="primary"
											name="instanceId"
											label="Instance ID"
											type="text"
											onBlur={formikProps.handleBlur}
											onChange={formikProps.handleChange}
											value={formikProps.values.instanceId}
											error={formikProps.errors.instanceId && formikProps.touched.instanceId}
											helperText={formikProps.errors.instanceId}
											required
										/>
										<TextField
											color="primary"
											name="accessKey"
											label="Access Key"
											type="text"
											onBlur={formikProps.handleBlur}
											onChange={formikProps.handleChange}
											value={formikProps.values.accessKey}
											error={formikProps.errors.accessKey && formikProps.touched.accessKey}
											helperText={formikProps.errors.accessKey}
											required
										/>
										<TextField
											autoFocus
											color="primary"
											name="secretKey"
											label="Secret Key"
											type="password"
											onBlur={formikProps.handleBlur}
											onChange={formikProps.handleChange}
											value={formikProps.values.secretKey}
											error={formikProps.errors.secretKey && formikProps.touched.secretKey}
											helperText={formikProps.errors.secretKey}
											required
										/>
									</Box>
									<Box display="flex" justifyContent="flex-end">
										{testingConnection &&
											<CircularProgress variant="indeterminate" color="primary" size="25px" />
										}
										<Button sx={actionTwoButtonStyle}
											onClick={() => testConnection(formikProps.values)}
											disabled={formikProps.values.region === '' || formikProps.values.instanceId === '' || formikProps.values.accessKey === '' || formikProps.values.secretKey === '' || testingConnection}
										>
											{testingConnection ? 'Testing Connection...' : 'Test Connection'}
										</Button>
									</Box>

									<Typography variant="overline">Configuration</Typography>

									<FormControl required>
										<InputLabel color="primary">Transfer Flow</InputLabel>
										<Select color="primary"
											label="Transfer Flow"
											name="transferFlowId"
											onBlur={formikProps.handleBlur}
											value={formikProps.values.transferFlowId}
											onChange={formikProps.handleChange}
											disabled={!connectionSucceeded && formikProps.values.transferFlowId === ''}
										>
											{flows.length === 0 &&
												<MenuItem key='none' value='' disabled>No flows available</MenuItem>
											}
											{flows.map((flow) => (
												<MenuItem key={flow.Name} value={flow.Id}>{flow.Name}</MenuItem>
											))}
										</Select>
									</FormControl>

								</Box>
							</CardContent>
							<CardActions>
								<Grid container justifyContent="flex-end">
									{savingValues &&
										<CircularProgress variant="indeterminate" color="primary" size="25px" />
									}
									<Button sx={actionOneButtonStyle} disabled={!formikProps.isValid || savingValues} type="submit">
										{savingValues ? 'Saving...' : 'Save'}
									</Button>
								</Grid>
							</CardActions>
						</Card>
					</form>
				)}
			</Formik>
		</Box>
	);
}
