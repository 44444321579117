import { get } from "aws-amplify/api";


export class AmazonConnect {
	static async getCampaigns(token, apiKey) {
		try {
			const campaigns = await get({
				apiName: 'cdyxoutreach',
				path: '/cci/amazon-connect/campaigns',
				options: {
					headers: {
						Authorization: `Bearer ${token}`,
						'x-api-key': apiKey
					}
				}
			}).response;
			return await campaigns.body.json();

		} catch (err) {
			console.error('Error getting campaigns', err);
		}

	}
}
