/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const simpleContactSearch = /* GraphQL */ `
  query SimpleContactSearch(
    $tenant: ID!
    $searchQuery: String
    $sort: [SimpleContactSearchSortInput]
    $limit: Int
    $nextToken: [String]
  ) {
    simpleContactSearch(
      tenant: $tenant
      searchQuery: $searchQuery
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        dnc
        updatedBy
        customFields
        expireDt
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        timeZoneBounds {
          min {
            offset
            dst
          }
          max {
            offset
            dst
          }
        }
        type
        optOuts {
          voice
          sms
          email
        }
        optIns {
          voice
          sms
          email
        }
        attempts
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const expandedContactSearch = /* GraphQL */ `
  query ExpandedContactSearch(
    $filter: AWSJSON
    $sort: [ExpandedContactSearchSortInput]
    $limit: Int
    $nextToken: String
  ) {
    expandedContactSearch(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        dnc
        updatedBy
        customFields
        expireDt
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        timeZoneBounds {
          min {
            offset
            dst
          }
          max {
            offset
            dst
          }
        }
        type
        optOuts {
          voice
          sms
          email
        }
        optIns {
          voice
          sms
          email
        }
        attempts
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const esQuery = /* GraphQL */ `
  query EsQuery(
    $model: EsQueryModel!
    $action: EsQueryAction!
    $query: AWSJSON!
  ) {
    esQuery(model: $model, action: $action, query: $query)
  }
`;
export const activityLogSuppressionSearch = /* GraphQL */ `
  query ActivityLogSuppressionSearch($filter: AWSJSON, $contactId: String!) {
    activityLogSuppressionSearch(filter: $filter, contactId: $contactId) {
      count
    }
  }
`;
export const contactMetadataSearch = /* GraphQL */ `
  query ContactMetadataSearch(
    $filter: AWSJSON
    $sort: [ContactMetadataSearchSortInput]
    $limit: Int
    $nextToken: String
  ) {
    contactMetadataSearch(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        contact
        field
        type
        poc {
          cell
          consent
          writtenConsent
          optOut
          preferred
          thirdParty
          dontCallOnWeekends
          callWindows {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
        }
        updatedBy
        createdAt
        updatedAt
        contactModel {
          id
          tenant
          externalId
          firstName
          lastName
          source
          phone
          cell
          email
          dateCreated
          address1
          address2
          city
          state
          zip
          dnc
          updatedBy
          customFields
          expireDt
          outboundCallerId
          balances {
            id
            type
            amount
            age
          }
          timeZoneBounds {
            min {
              offset
              dst
            }
            max {
              offset
              dst
            }
          }
          type
          optOuts {
            voice
            sms
            email
          }
          optIns {
            voice
            sms
            email
          }
          attempts
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getTenantSettings = /* GraphQL */ `
  query GetTenantSettings($id: ID!) {
    getTenantSettings(id: $id) {
      id
      icApp
      icVendor
      icBU
      icUserName
      icPassword
      icGetAuthTokenURL
      icAccessKey
      icAppSecret
      icDiscoveryDomain
      icScriptName
      ftpImport
      ftpExport
      dashBoardCharts
      e2DashCharts
      ppDashCharts
      e2ClientId
      e2ClientSecret
      e2DynamTenantId
      onPremAccessUrl
      onPremVersion
      onPremAuthUrl
      apiKey
      licenses
      customFields {
        name
        displayName
        type
        isPoc
      }
      dynamicsConnection
      dynamicsDispoFilter
      email
      dispositionScoring {
        noAnswer
        answer
        noAgentAvailableToDial
        noAgentOnAnswer
        overApproving
      }
      telephonyProvider
      telephoneProviderInfo {
        amazonConnect {
          accessKey
          secretKey
          instanceId
          region
          queueId
          transferFlowId
        }
        incontact {
          icApp
          icVendor
          icBU
          icUserName
          icPassword
          icGetAuthTokenURL
          icAccessKey
          icAppSecret
          icDiscoveryDomain
          icScriptName
        }
        nobelbiz {
          nobelbizTenantId
          token
          instanceId
          username
          password
          encodedAuth
        }
        genesys {
          clientId
          clientSecret
          cloudRegion
          queueId
          wrapUps
          timerFlow
          chime {
            region
            outboundDialNumber
            sipMediaApplicationId
          }
        }
        webex {
          contactCenter {
            region
            organizationId
            clientId
            clientSecret
          }
          connect {
            region
            serviceKey
          }
          chime {
            region
            outboundDialNumber
            sipMediaApplicationId
          }
        }
        ringCX {
          clientId
          clientSecret
          serverUrl
          jwt
          adminAccountId
        }
      }
      dncDotComSettings {
        orgId
        projectId
        loginId
      }
      ipAuthentication
      ipRanges
      allowAutoMode
      overrideDialToAgentRatio
      dialToAgentRatio
      createdAt
      updatedAt
    }
  }
`;
export const listTenantSettingss = /* GraphQL */ `
  query ListTenantSettingss(
    $filter: ModelTenantSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenantSettingss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        icApp
        icVendor
        icBU
        icUserName
        icPassword
        icGetAuthTokenURL
        icAccessKey
        icAppSecret
        icDiscoveryDomain
        icScriptName
        ftpImport
        ftpExport
        dashBoardCharts
        e2DashCharts
        ppDashCharts
        e2ClientId
        e2ClientSecret
        e2DynamTenantId
        onPremAccessUrl
        onPremVersion
        onPremAuthUrl
        apiKey
        licenses
        customFields {
          name
          displayName
          type
          isPoc
        }
        dynamicsConnection
        dynamicsDispoFilter
        email
        dispositionScoring {
          noAnswer
          answer
          noAgentAvailableToDial
          noAgentOnAnswer
          overApproving
        }
        telephonyProvider
        telephoneProviderInfo {
          amazonConnect {
            accessKey
            secretKey
            instanceId
            region
            queueId
            transferFlowId
          }
          incontact {
            icApp
            icVendor
            icBU
            icUserName
            icPassword
            icGetAuthTokenURL
            icAccessKey
            icAppSecret
            icDiscoveryDomain
            icScriptName
          }
          nobelbiz {
            nobelbizTenantId
            token
            instanceId
            username
            password
            encodedAuth
          }
          genesys {
            clientId
            clientSecret
            cloudRegion
            queueId
            wrapUps
            timerFlow
            chime {
              region
              outboundDialNumber
              sipMediaApplicationId
            }
          }
          webex {
            contactCenter {
              region
              organizationId
              clientId
              clientSecret
            }
            connect {
              region
              serviceKey
            }
            chime {
              region
              outboundDialNumber
              sipMediaApplicationId
            }
          }
          ringCX {
            clientId
            clientSecret
            serverUrl
            jwt
            adminAccountId
          }
        }
        dncDotComSettings {
          orgId
          projectId
          loginId
        }
        ipAuthentication
        ipRanges
        allowAutoMode
        overrideDialToAgentRatio
        dialToAgentRatio
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFtpImport = /* GraphQL */ `
  query GetFtpImport($id: ID!) {
    getFtpImport(id: $id) {
      id
      tenant
      name
      host
      username
      password
      path
      privateKey
      port
      fieldMappingId
      lastCheck
      fileNameToSource
      algorithms {
        cipher
      }
      readyTimeout
      reportEmail
      delimiter
      createdAt
      updatedAt
    }
  }
`;
export const listFtpImports = /* GraphQL */ `
  query ListFtpImports(
    $filter: ModelFtpImportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFtpImports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        name
        host
        username
        password
        path
        privateKey
        port
        fieldMappingId
        lastCheck
        fileNameToSource
        algorithms {
          cipher
        }
        readyTimeout
        reportEmail
        delimiter
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFieldsMapping = /* GraphQL */ `
  query GetFieldsMapping($id: ID!) {
    getFieldsMapping(id: $id) {
      id
      tenant
      name
      mappings {
        fromField
        toField
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFieldsMappings = /* GraphQL */ `
  query ListFieldsMappings(
    $filter: ModelFieldsMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFieldsMappings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        name
        mappings {
          fromField
          toField
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContactMetadataFieldMappings = /* GraphQL */ `
  query GetContactMetadataFieldMappings($id: ID!) {
    getContactMetadataFieldMappings(id: $id) {
      id
      tenant
      name
      mappings {
        fromField
        toField
      }
      createdAt
      updatedAt
    }
  }
`;
export const listContactMetadataFieldMappingss = /* GraphQL */ `
  query ListContactMetadataFieldMappingss(
    $filter: ModelContactMetadataFieldMappingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactMetadataFieldMappingss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        name
        mappings {
          fromField
          toField
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContactCallWindowsFieldMappings = /* GraphQL */ `
  query GetContactCallWindowsFieldMappings($id: ID!) {
    getContactCallWindowsFieldMappings(id: $id) {
      id
      tenant
      name
      mappings {
        fromField
        toField
      }
      createdAt
      updatedAt
    }
  }
`;
export const listContactCallWindowsFieldMappingss = /* GraphQL */ `
  query ListContactCallWindowsFieldMappingss(
    $filter: ModelContactCallWindowsFieldMappingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactCallWindowsFieldMappingss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        name
        mappings {
          fromField
          toField
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCampaign = /* GraphQL */ `
  query GetCampaign($id: ID!) {
    getCampaign(id: $id) {
      id
      tenant
      name
      segments
      segmentSchedule
      campaignSchedule
      static
      sort
      skillId
      skillName
      skillSource
      campaignProfileId
      profile {
        id
        tenant
        name
        useAi
        flowRatio
        scrubber {
          cell
          natDnc
          stateDnc
          orgDnc
          phoneDup
          contactDup
        }
        retry {
          minDuration
          maxAttempts
          maxContacts
          custRetry
          ratio
        }
        ai {
          org
          system
        }
        pushContacts
        startTime
        endTime
        buffer
        channels {
          type
          order
          retryDelay
          maxAttempts
          maxContacts
          custRetry
          paymentProfileId
          paymentProfileName
          dispositions
          cadence
          dialingScript
        }
        ratio
        dontCallOnWeekends
        callWindow {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
        clickToCall
        callWindows {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
        dispositionScoring {
          noAnswer
          answer
          noAgentAvailableToDial
          noAgentOnAnswer
          overApproving
        }
        overrideDispositionScoring
        overrideDialToAgentRatio
        dialToAgentRatio
        freshLIFO
        retryLIFO
        createdAt
        updatedAt
      }
      status
      expireDate
      stopOnCompletion
      cci
      autoMode
      cciMetaData
      createdAt
      updatedAt
    }
  }
`;
export const listCampaigns = /* GraphQL */ `
  query ListCampaigns(
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        name
        segments
        segmentSchedule
        campaignSchedule
        static
        sort
        skillId
        skillName
        skillSource
        campaignProfileId
        profile {
          id
          tenant
          name
          useAi
          flowRatio
          scrubber {
            cell
            natDnc
            stateDnc
            orgDnc
            phoneDup
            contactDup
          }
          retry {
            minDuration
            maxAttempts
            maxContacts
            custRetry
            ratio
          }
          ai {
            org
            system
          }
          pushContacts
          startTime
          endTime
          buffer
          channels {
            type
            order
            retryDelay
            maxAttempts
            maxContacts
            custRetry
            paymentProfileId
            paymentProfileName
            dispositions
            cadence
            dialingScript
          }
          ratio
          dontCallOnWeekends
          callWindow {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
          clickToCall
          callWindows {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
          dispositionScoring {
            noAnswer
            answer
            noAgentAvailableToDial
            noAgentOnAnswer
            overApproving
          }
          overrideDispositionScoring
          overrideDialToAgentRatio
          dialToAgentRatio
          freshLIFO
          retryLIFO
          createdAt
          updatedAt
        }
        status
        expireDate
        stopOnCompletion
        cci
        autoMode
        cciMetaData
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      tenant
      name
      useAi
      flowRatio
      scrubber {
        cell
        natDnc
        stateDnc
        orgDnc
        phoneDup
        contactDup
      }
      retry {
        minDuration
        maxAttempts
        maxContacts
        custRetry
        ratio
      }
      ai {
        org
        system
      }
      pushContacts
      startTime
      endTime
      buffer
      channels {
        type
        order
        retryDelay
        maxAttempts
        maxContacts
        custRetry
        paymentProfileId
        paymentProfileName
        dispositions
        cadence
        dialingScript
      }
      ratio
      dontCallOnWeekends
      callWindow {
        days
        tmz {
          offset
          dst
        }
        beginTime {
          hour
          minute
        }
        endTime {
          hour
          minute
        }
        callWindowQuery {
          field
          operator
          value
        }
        default
        order
      }
      clickToCall
      callWindows {
        days
        tmz {
          offset
          dst
        }
        beginTime {
          hour
          minute
        }
        endTime {
          hour
          minute
        }
        callWindowQuery {
          field
          operator
          value
        }
        default
        order
      }
      dispositionScoring {
        noAnswer
        answer
        noAgentAvailableToDial
        noAgentOnAnswer
        overApproving
      }
      overrideDispositionScoring
      overrideDialToAgentRatio
      dialToAgentRatio
      freshLIFO
      retryLIFO
      createdAt
      updatedAt
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        name
        useAi
        flowRatio
        scrubber {
          cell
          natDnc
          stateDnc
          orgDnc
          phoneDup
          contactDup
        }
        retry {
          minDuration
          maxAttempts
          maxContacts
          custRetry
          ratio
        }
        ai {
          org
          system
        }
        pushContacts
        startTime
        endTime
        buffer
        channels {
          type
          order
          retryDelay
          maxAttempts
          maxContacts
          custRetry
          paymentProfileId
          paymentProfileName
          dispositions
          cadence
          dialingScript
        }
        ratio
        dontCallOnWeekends
        callWindow {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
        clickToCall
        callWindows {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
        dispositionScoring {
          noAnswer
          answer
          noAgentAvailableToDial
          noAgentOnAnswer
          overApproving
        }
        overrideDispositionScoring
        overrideDialToAgentRatio
        dialToAgentRatio
        freshLIFO
        retryLIFO
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getManualProfile = /* GraphQL */ `
  query GetManualProfile($id: ID!) {
    getManualProfile(id: $id) {
      id
      tenant
      name
      dialingProfile
      suppressionProfile
      skills
      createdAt
      updatedAt
    }
  }
`;
export const listManualProfiles = /* GraphQL */ `
  query ListManualProfiles(
    $filter: ModelManualProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listManualProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        name
        dialingProfile
        suppressionProfile
        skills
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSegment = /* GraphQL */ `
  query GetSegment($id: ID!) {
    getSegment(id: $id) {
      id
      tenant
      externalId
      name
      description
      query {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      filter {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      fieldsMappingId
      suppressionID
      suppression {
        id
        tenant
        name
        result
        activityLogQuerySuppressionEnabled
        activityLogQuerySuppression {
          query
          rawQuery
          threshold
        }
        pocQuerySuppressionEnabled
        pocQuerySuppression {
          query
          rawQuery
          suppressIfWeekendAndCantCallOnWeekends
        }
        restSuppressionEnabled
        restSuppression {
          endpoint
          method
          params {
            key
            value
            description
          }
          headers {
            key
            value
            description
          }
          auth {
            type
            info
          }
          response {
            path
            type
            value
            options
          }
          suppressOnError
          dispositionOnError
        }
        dncDotComEnabled
        dncDotCom {
          scrub
          state
          numberReassignment
          knownLitigator
          reassignmentSince
        }
        dncListSuppressionEnabled
        dncListSuppressions
        contactFieldSuppressionEnabled
        contactFieldSuppressions
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSegments = /* GraphQL */ `
  query ListSegments(
    $filter: ModelSegmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSegments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        externalId
        name
        description
        query {
          qbQuery
          searchFilter
          sort {
            field
            direction
          }
        }
        filter {
          qbQuery
          searchFilter
          sort {
            field
            direction
          }
        }
        fieldsMappingId
        suppressionID
        suppression {
          id
          tenant
          name
          result
          activityLogQuerySuppressionEnabled
          activityLogQuerySuppression {
            query
            rawQuery
            threshold
          }
          pocQuerySuppressionEnabled
          pocQuerySuppression {
            query
            rawQuery
            suppressIfWeekendAndCantCallOnWeekends
          }
          restSuppressionEnabled
          restSuppression {
            endpoint
            method
            params {
              key
              value
              description
            }
            headers {
              key
              value
              description
            }
            auth {
              type
              info
            }
            response {
              path
              type
              value
              options
            }
            suppressOnError
            dispositionOnError
          }
          dncDotComEnabled
          dncDotCom {
            scrub
            state
            numberReassignment
            knownLitigator
            reassignmentSince
          }
          dncListSuppressionEnabled
          dncListSuppressions
          contactFieldSuppressionEnabled
          contactFieldSuppressions
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCampaignLog = /* GraphQL */ `
  query GetCampaignLog($id: ID!) {
    getCampaignLog(id: $id) {
      id
      tenant
      campaign
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const listCampaignLogs = /* GraphQL */ `
  query ListCampaignLogs(
    $filter: ModelCampaignLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampaignLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        campaign
        type
        metaData
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConfigProfile = /* GraphQL */ `
  query GetConfigProfile($id: ID!) {
    getConfigProfile(id: $id) {
      id
      tenant
      name
      direction
      mediaType
      crmEntity
      org {
        name
        id
        url
        version
      }
      connection {
        dynamicsTenant
        appSecret
        clientId
        altUrl
        authUrl
        onPremVersion
      }
      skillIds
      multiSearch
      search
      searchAction
      activityEntityMap
      logging
      postLogging
      loggingAction
      createdAt
      updatedAt
    }
  }
`;
export const listConfigProfiles = /* GraphQL */ `
  query ListConfigProfiles(
    $filter: ModelConfigProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConfigProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        name
        direction
        mediaType
        crmEntity
        org {
          name
          id
          url
          version
        }
        connection {
          dynamicsTenant
          appSecret
          clientId
          altUrl
          authUrl
          onPremVersion
        }
        skillIds
        multiSearch
        search
        searchAction
        activityEntityMap
        logging
        postLogging
        loggingAction
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCurfewXCodes = /* GraphQL */ `
  query GetCurfewXCodes($id: ID!) {
    getCurfewXCodes(id: $id) {
      id
      minOffSet
      maxOffSet
      dls
      createdAt
      updatedAt
    }
  }
`;
export const listCurfewXCodess = /* GraphQL */ `
  query ListCurfewXCodess(
    $filter: ModelCurfewXCodesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCurfewXCodess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        minOffSet
        maxOffSet
        dls
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPaymentProfile = /* GraphQL */ `
  query GetPaymentProfile($id: ID!) {
    getPaymentProfile(id: $id) {
      id
      tenant
      name
      vendorId
      apiKey
      descriptor
      ttl
      siteLogo
      header1
      header2
      sitePrimary
      siteSecondary
      minType
      minRate
      supportEmail
      supportPhone
      promptPaySkills
      channels
      c2a
      subject
      sendingEmail
      createdAt
      updatedAt
    }
  }
`;
export const listPaymentProfiles = /* GraphQL */ `
  query ListPaymentProfiles(
    $filter: ModelPaymentProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        name
        vendorId
        apiKey
        descriptor
        ttl
        siteLogo
        header1
        header2
        sitePrimary
        siteSecondary
        minType
        minRate
        supportEmail
        supportPhone
        promptPaySkills
        channels
        c2a
        subject
        sendingEmail
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPaymentTemplate = /* GraphQL */ `
  query GetPaymentTemplate($id: ID!) {
    getPaymentTemplate(id: $id) {
      id
      tenant
      name
      textBody
      type
      createdAt
      updatedAt
    }
  }
`;
export const listPaymentTemplates = /* GraphQL */ `
  query ListPaymentTemplates(
    $filter: ModelPaymentTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        name
        textBody
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomDispoMapping = /* GraphQL */ `
  query GetCustomDispoMapping($id: ID!) {
    getCustomDispoMapping(id: $id) {
      id
      tenant
      skill
      custDispId
      custDispName
      dispClassId
      dispCode
      dispDescription
      dispClassName
      createdAt
      updatedAt
    }
  }
`;
export const listCustomDispoMappings = /* GraphQL */ `
  query ListCustomDispoMappings(
    $filter: ModelCustomDispoMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomDispoMappings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        skill
        custDispId
        custDispName
        dispClassId
        dispCode
        dispDescription
        dispClassName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomDispositions = /* GraphQL */ `
  query GetCustomDispositions($id: ID!) {
    getCustomDispositions(id: $id) {
      id
      tenant
      name
      description
      classification
      previewDispo
      noteRequired
      amountRequired
      rescheduleDateRequired
      createdAt
      updatedAt
    }
  }
`;
export const listCustomDispositionss = /* GraphQL */ `
  query ListCustomDispositionss(
    $filter: ModelCustomDispositionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomDispositionss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        name
        description
        classification
        previewDispo
        noteRequired
        amountRequired
        rescheduleDateRequired
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDncList = /* GraphQL */ `
  query GetDncList($id: ID!) {
    getDNCList(id: $id) {
      id
      tenant
      name
      numbers {
        items {
          id
          tenant
          phoneNumber
          dncList
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDncLists = /* GraphQL */ `
  query ListDncLists(
    $filter: ModelDNCListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDNCLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        name
        numbers {
          items {
            id
            tenant
            phoneNumber
            dncList
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDnc = /* GraphQL */ `
  query GetDnc($id: ID!) {
    getDNC(id: $id) {
      id
      tenant
      phoneNumber
      dncList
      createdAt
      updatedAt
    }
  }
`;
export const listDnCs = /* GraphQL */ `
  query ListDnCs(
    $filter: ModelDNCFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDNCs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        phoneNumber
        dncList
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContactFieldSuppressionList = /* GraphQL */ `
  query GetContactFieldSuppressionList($id: ID!) {
    getContactFieldSuppressionList(id: $id) {
      id
      tenant
      name
      contactField
      fields {
        items {
          id
          tenant
          contactFieldValue
          contactFieldSuppressionList
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listContactFieldSuppressionLists = /* GraphQL */ `
  query ListContactFieldSuppressionLists(
    $filter: ModelContactFieldSuppressionListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactFieldSuppressionLists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        name
        contactField
        fields {
          items {
            id
            tenant
            contactFieldValue
            contactFieldSuppressionList
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContactFieldSuppression = /* GraphQL */ `
  query GetContactFieldSuppression($id: ID!) {
    getContactFieldSuppression(id: $id) {
      id
      tenant
      contactFieldValue
      contactFieldSuppressionList
      createdAt
      updatedAt
    }
  }
`;
export const listContactFieldSuppressions = /* GraphQL */ `
  query ListContactFieldSuppressions(
    $filter: ModelContactFieldSuppressionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactFieldSuppressions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        contactFieldValue
        contactFieldSuppressionList
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSuppression = /* GraphQL */ `
  query GetSuppression($id: ID!) {
    getSuppression(id: $id) {
      id
      tenant
      name
      result
      activityLogQuerySuppressionEnabled
      activityLogQuerySuppression {
        query
        rawQuery
        threshold
      }
      pocQuerySuppressionEnabled
      pocQuerySuppression {
        query
        rawQuery
        suppressIfWeekendAndCantCallOnWeekends
      }
      restSuppressionEnabled
      restSuppression {
        endpoint
        method
        params {
          key
          value
          description
        }
        headers {
          key
          value
          description
        }
        auth {
          type
          info
        }
        response {
          path
          type
          value
          options
        }
        suppressOnError
        dispositionOnError
      }
      dncDotComEnabled
      dncDotCom {
        scrub
        state
        numberReassignment
        knownLitigator
        reassignmentSince
      }
      dncListSuppressionEnabled
      dncListSuppressions
      contactFieldSuppressionEnabled
      contactFieldSuppressions
      createdAt
      updatedAt
    }
  }
`;
export const listSuppressions = /* GraphQL */ `
  query ListSuppressions(
    $filter: ModelSuppressionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSuppressions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        name
        result
        activityLogQuerySuppressionEnabled
        activityLogQuerySuppression {
          query
          rawQuery
          threshold
        }
        pocQuerySuppressionEnabled
        pocQuerySuppression {
          query
          rawQuery
          suppressIfWeekendAndCantCallOnWeekends
        }
        restSuppressionEnabled
        restSuppression {
          endpoint
          method
          params {
            key
            value
            description
          }
          headers {
            key
            value
            description
          }
          auth {
            type
            info
          }
          response {
            path
            type
            value
            options
          }
          suppressOnError
          dispositionOnError
        }
        dncDotComEnabled
        dncDotCom {
          scrub
          state
          numberReassignment
          knownLitigator
          reassignmentSince
        }
        dncListSuppressionEnabled
        dncListSuppressions
        contactFieldSuppressionEnabled
        contactFieldSuppressions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOAuthLookUp = /* GraphQL */ `
  query GetOAuthLookUp($id: ID!) {
    getOAuthLookUp(id: $id) {
      id
      clientSecret
      cognitoUserName
      cognitoPassword
      createdAt
      updatedAt
    }
  }
`;
export const listOAuthLookUps = /* GraphQL */ `
  query ListOAuthLookUps(
    $filter: ModelOAuthLookUpFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOAuthLookUps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientSecret
        cognitoUserName
        cognitoPassword
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const ftpImportsByTenant = /* GraphQL */ `
  query FtpImportsByTenant(
    $tenant: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFtpImportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ftpImportsByTenant(
      tenant: $tenant
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        name
        host
        username
        password
        path
        privateKey
        port
        fieldMappingId
        lastCheck
        fileNameToSource
        algorithms {
          cipher
        }
        readyTimeout
        reportEmail
        delimiter
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tenantMappings = /* GraphQL */ `
  query TenantMappings(
    $tenant: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFieldsMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantMappings(
      tenant: $tenant
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        name
        mappings {
          fromField
          toField
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tenantContactMetadataMappings = /* GraphQL */ `
  query TenantContactMetadataMappings(
    $tenant: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactMetadataFieldMappingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantContactMetadataMappings(
      tenant: $tenant
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        name
        mappings {
          fromField
          toField
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const contactCallWindowsFieldMappings = /* GraphQL */ `
  query ContactCallWindowsFieldMappings(
    $tenant: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactCallWindowsFieldMappingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactCallWindowsFieldMappings(
      tenant: $tenant
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        name
        mappings {
          fromField
          toField
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const campaignsByTenant = /* GraphQL */ `
  query CampaignsByTenant(
    $tenant: ID
    $skillName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    campaignsByTenant(
      tenant: $tenant
      skillName: $skillName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        name
        segments
        segmentSchedule
        campaignSchedule
        static
        sort
        skillId
        skillName
        skillSource
        campaignProfileId
        profile {
          id
          tenant
          name
          useAi
          flowRatio
          scrubber {
            cell
            natDnc
            stateDnc
            orgDnc
            phoneDup
            contactDup
          }
          retry {
            minDuration
            maxAttempts
            maxContacts
            custRetry
            ratio
          }
          ai {
            org
            system
          }
          pushContacts
          startTime
          endTime
          buffer
          channels {
            type
            order
            retryDelay
            maxAttempts
            maxContacts
            custRetry
            paymentProfileId
            paymentProfileName
            dispositions
            cadence
            dialingScript
          }
          ratio
          dontCallOnWeekends
          callWindow {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
          clickToCall
          callWindows {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
          dispositionScoring {
            noAnswer
            answer
            noAgentAvailableToDial
            noAgentOnAnswer
            overApproving
          }
          overrideDispositionScoring
          overrideDialToAgentRatio
          dialToAgentRatio
          freshLIFO
          retryLIFO
          createdAt
          updatedAt
        }
        status
        expireDate
        stopOnCompletion
        cci
        autoMode
        cciMetaData
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const campaignsByStatus = /* GraphQL */ `
  query CampaignsByStatus(
    $status: CampaignStatus
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    campaignsByStatus(
      status: $status
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        name
        segments
        segmentSchedule
        campaignSchedule
        static
        sort
        skillId
        skillName
        skillSource
        campaignProfileId
        profile {
          id
          tenant
          name
          useAi
          flowRatio
          scrubber {
            cell
            natDnc
            stateDnc
            orgDnc
            phoneDup
            contactDup
          }
          retry {
            minDuration
            maxAttempts
            maxContacts
            custRetry
            ratio
          }
          ai {
            org
            system
          }
          pushContacts
          startTime
          endTime
          buffer
          channels {
            type
            order
            retryDelay
            maxAttempts
            maxContacts
            custRetry
            paymentProfileId
            paymentProfileName
            dispositions
            cadence
            dialingScript
          }
          ratio
          dontCallOnWeekends
          callWindow {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
          clickToCall
          callWindows {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
          dispositionScoring {
            noAnswer
            answer
            noAgentAvailableToDial
            noAgentOnAnswer
            overApproving
          }
          overrideDispositionScoring
          overrideDialToAgentRatio
          dialToAgentRatio
          freshLIFO
          retryLIFO
          createdAt
          updatedAt
        }
        status
        expireDate
        stopOnCompletion
        cci
        autoMode
        cciMetaData
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const profileByTenant = /* GraphQL */ `
  query ProfileByTenant(
    $tenant: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    profileByTenant(
      tenant: $tenant
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        name
        useAi
        flowRatio
        scrubber {
          cell
          natDnc
          stateDnc
          orgDnc
          phoneDup
          contactDup
        }
        retry {
          minDuration
          maxAttempts
          maxContacts
          custRetry
          ratio
        }
        ai {
          org
          system
        }
        pushContacts
        startTime
        endTime
        buffer
        channels {
          type
          order
          retryDelay
          maxAttempts
          maxContacts
          custRetry
          paymentProfileId
          paymentProfileName
          dispositions
          cadence
          dialingScript
        }
        ratio
        dontCallOnWeekends
        callWindow {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
        clickToCall
        callWindows {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
        dispositionScoring {
          noAnswer
          answer
          noAgentAvailableToDial
          noAgentOnAnswer
          overApproving
        }
        overrideDispositionScoring
        overrideDialToAgentRatio
        dialToAgentRatio
        freshLIFO
        retryLIFO
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const manualProfileByTenant = /* GraphQL */ `
  query ManualProfileByTenant(
    $tenant: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelManualProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    manualProfileByTenant(
      tenant: $tenant
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        name
        dialingProfile
        suppressionProfile
        skills
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const segmentByTenant = /* GraphQL */ `
  query SegmentByTenant(
    $tenant: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSegmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    segmentByTenant(
      tenant: $tenant
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        externalId
        name
        description
        query {
          qbQuery
          searchFilter
          sort {
            field
            direction
          }
        }
        filter {
          qbQuery
          searchFilter
          sort {
            field
            direction
          }
        }
        fieldsMappingId
        suppressionID
        suppression {
          id
          tenant
          name
          result
          activityLogQuerySuppressionEnabled
          activityLogQuerySuppression {
            query
            rawQuery
            threshold
          }
          pocQuerySuppressionEnabled
          pocQuerySuppression {
            query
            rawQuery
            suppressIfWeekendAndCantCallOnWeekends
          }
          restSuppressionEnabled
          restSuppression {
            endpoint
            method
            params {
              key
              value
              description
            }
            headers {
              key
              value
              description
            }
            auth {
              type
              info
            }
            response {
              path
              type
              value
              options
            }
            suppressOnError
            dispositionOnError
          }
          dncDotComEnabled
          dncDotCom {
            scrub
            state
            numberReassignment
            knownLitigator
            reassignmentSince
          }
          dncListSuppressionEnabled
          dncListSuppressions
          contactFieldSuppressionEnabled
          contactFieldSuppressions
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const segmentByTenantExternalId = /* GraphQL */ `
  query SegmentByTenantExternalId(
    $tenant: ID
    $externalId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSegmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    segmentByTenantExternalId(
      tenant: $tenant
      externalId: $externalId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        externalId
        name
        description
        query {
          qbQuery
          searchFilter
          sort {
            field
            direction
          }
        }
        filter {
          qbQuery
          searchFilter
          sort {
            field
            direction
          }
        }
        fieldsMappingId
        suppressionID
        suppression {
          id
          tenant
          name
          result
          activityLogQuerySuppressionEnabled
          activityLogQuerySuppression {
            query
            rawQuery
            threshold
          }
          pocQuerySuppressionEnabled
          pocQuerySuppression {
            query
            rawQuery
            suppressIfWeekendAndCantCallOnWeekends
          }
          restSuppressionEnabled
          restSuppression {
            endpoint
            method
            params {
              key
              value
              description
            }
            headers {
              key
              value
              description
            }
            auth {
              type
              info
            }
            response {
              path
              type
              value
              options
            }
            suppressOnError
            dispositionOnError
          }
          dncDotComEnabled
          dncDotCom {
            scrub
            state
            numberReassignment
            knownLitigator
            reassignmentSince
          }
          dncListSuppressionEnabled
          dncListSuppressions
          contactFieldSuppressionEnabled
          contactFieldSuppressions
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const configProfielsByTenant = /* GraphQL */ `
  query ConfigProfielsByTenant(
    $tenant: ID
    $mediaType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConfigProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    configProfielsByTenant(
      tenant: $tenant
      mediaType: $mediaType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        name
        direction
        mediaType
        crmEntity
        org {
          name
          id
          url
          version
        }
        connection {
          dynamicsTenant
          appSecret
          clientId
          altUrl
          authUrl
          onPremVersion
        }
        skillIds
        multiSearch
        search
        searchAction
        activityEntityMap
        logging
        postLogging
        loggingAction
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tenantPayProfiles = /* GraphQL */ `
  query TenantPayProfiles(
    $tenant: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantPayProfiles(
      tenant: $tenant
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        name
        vendorId
        apiKey
        descriptor
        ttl
        siteLogo
        header1
        header2
        sitePrimary
        siteSecondary
        minType
        minRate
        supportEmail
        supportPhone
        promptPaySkills
        channels
        c2a
        subject
        sendingEmail
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tenantPayTemplates = /* GraphQL */ `
  query TenantPayTemplates(
    $tenant: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantPayTemplates(
      tenant: $tenant
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        name
        textBody
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tenantCustomDispos = /* GraphQL */ `
  query TenantCustomDispos(
    $tenant: ID
    $skillCustDispId: ModelCustomDispoMappingTenantCustomDisposCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCustomDispoMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantCustomDispos(
      tenant: $tenant
      skillCustDispId: $skillCustDispId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        skill
        custDispId
        custDispName
        dispClassId
        dispCode
        dispDescription
        dispClassName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const dncByList = /* GraphQL */ `
  query DncByList(
    $dncList: ID
    $phoneNumber: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDNCFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dncByList(
      dncList: $dncList
      phoneNumber: $phoneNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        phoneNumber
        dncList
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const dncByDate = /* GraphQL */ `
  query DncByDate(
    $dncList: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDNCFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dncByDate(
      dncList: $dncList
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        phoneNumber
        dncList
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const contactFieldSuppressionByList = /* GraphQL */ `
  query ContactFieldSuppressionByList(
    $contactFieldSuppressionList: ID
    $contactFieldValue: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactFieldSuppressionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactFieldSuppressionByList(
      contactFieldSuppressionList: $contactFieldSuppressionList
      contactFieldValue: $contactFieldValue
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        contactFieldValue
        contactFieldSuppressionList
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchCampaigns = /* GraphQL */ `
  query SearchCampaigns(
    $filter: SearchableCampaignFilterInput
    $sort: SearchableCampaignSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCampaigns(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        tenant
        name
        segments
        segmentSchedule
        campaignSchedule
        static
        sort
        skillId
        skillName
        skillSource
        campaignProfileId
        profile {
          id
          tenant
          name
          useAi
          flowRatio
          scrubber {
            cell
            natDnc
            stateDnc
            orgDnc
            phoneDup
            contactDup
          }
          retry {
            minDuration
            maxAttempts
            maxContacts
            custRetry
            ratio
          }
          ai {
            org
            system
          }
          pushContacts
          startTime
          endTime
          buffer
          channels {
            type
            order
            retryDelay
            maxAttempts
            maxContacts
            custRetry
            paymentProfileId
            paymentProfileName
            dispositions
            cadence
            dialingScript
          }
          ratio
          dontCallOnWeekends
          callWindow {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
          clickToCall
          callWindows {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
          dispositionScoring {
            noAnswer
            answer
            noAgentAvailableToDial
            noAgentOnAnswer
            overApproving
          }
          overrideDispositionScoring
          overrideDialToAgentRatio
          dialToAgentRatio
          freshLIFO
          retryLIFO
          createdAt
          updatedAt
        }
        status
        expireDate
        stopOnCompletion
        cci
        autoMode
        cciMetaData
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchSegments = /* GraphQL */ `
  query SearchSegments(
    $filter: SearchableSegmentFilterInput
    $sort: SearchableSegmentSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchSegments(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        tenant
        externalId
        name
        description
        query {
          qbQuery
          searchFilter
          sort {
            field
            direction
          }
        }
        filter {
          qbQuery
          searchFilter
          sort {
            field
            direction
          }
        }
        fieldsMappingId
        suppressionID
        suppression {
          id
          tenant
          name
          result
          activityLogQuerySuppressionEnabled
          activityLogQuerySuppression {
            query
            rawQuery
            threshold
          }
          pocQuerySuppressionEnabled
          pocQuerySuppression {
            query
            rawQuery
            suppressIfWeekendAndCantCallOnWeekends
          }
          restSuppressionEnabled
          restSuppression {
            endpoint
            method
            params {
              key
              value
              description
            }
            headers {
              key
              value
              description
            }
            auth {
              type
              info
            }
            response {
              path
              type
              value
              options
            }
            suppressOnError
            dispositionOnError
          }
          dncDotComEnabled
          dncDotCom {
            scrub
            state
            numberReassignment
            knownLitigator
            reassignmentSince
          }
          dncListSuppressionEnabled
          dncListSuppressions
          contactFieldSuppressionEnabled
          contactFieldSuppressions
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchCampaignLogs = /* GraphQL */ `
  query SearchCampaignLogs(
    $filter: SearchableCampaignLogFilterInput
    $sort: SearchableCampaignLogSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCampaignLogs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        tenant
        campaign
        type
        metaData
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchConfigProfiles = /* GraphQL */ `
  query SearchConfigProfiles(
    $filter: SearchableConfigProfileFilterInput
    $sort: SearchableConfigProfileSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchConfigProfiles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        tenant
        name
        direction
        mediaType
        crmEntity
        org {
          name
          id
          url
          version
        }
        connection {
          dynamicsTenant
          appSecret
          clientId
          altUrl
          authUrl
          onPremVersion
        }
        skillIds
        multiSearch
        search
        searchAction
        activityEntityMap
        logging
        postLogging
        loggingAction
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchOAuthLookUps = /* GraphQL */ `
  query SearchOAuthLookUps(
    $filter: SearchableOAuthLookUpFilterInput
    $sort: SearchableOAuthLookUpSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchOAuthLookUps(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        clientSecret
        cognitoUserName
        cognitoPassword
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        dnc
        updatedBy
        customFields
        expireDt
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        timeZoneBounds {
          min {
            offset
            dst
          }
          max {
            offset
            dst
          }
        }
        type
        optOuts {
          voice
          sms
          email
        }
        optIns {
          voice
          sms
          email
        }
        attempts
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      tenant
      externalId
      firstName
      lastName
      source
      phone
      cell
      email
      dateCreated
      address1
      address2
      city
      state
      zip
      dnc
      updatedBy
      customFields
      expireDt
      outboundCallerId
      balances {
        id
        type
        amount
        age
      }
      timeZoneBounds {
        min {
          offset
          dst
        }
        max {
          offset
          dst
        }
      }
      type
      optOuts {
        voice
        sms
        email
      }
      optIns {
        voice
        sms
        email
      }
      attempts
      createdAt
      updatedAt
    }
  }
`;
export const tenantExternalId = /* GraphQL */ `
  query TenantExternalId(
    $tenant: ID
    $externalId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantExternalId(
      tenant: $tenant
      externalId: $externalId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        dnc
        updatedBy
        customFields
        expireDt
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        timeZoneBounds {
          min {
            offset
            dst
          }
          max {
            offset
            dst
          }
        }
        type
        optOuts {
          voice
          sms
          email
        }
        optIns {
          voice
          sms
          email
        }
        attempts
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchContacts = /* GraphQL */ `
  query SearchContacts(
    $filter: SearchableContactFilterInput
    $sort: SearchableContactSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchContacts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        dnc
        updatedBy
        customFields
        expireDt
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        timeZoneBounds {
          min {
            offset
            dst
          }
          max {
            offset
            dst
          }
        }
        type
        optOuts {
          voice
          sms
          email
        }
        optIns {
          voice
          sms
          email
        }
        attempts
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const getActivityLog = /* GraphQL */ `
  query GetActivityLog($id: ID!) {
    getActivityLog(id: $id) {
      id
      tenant
      type
      disposition
      title
      body
      activityLogContactId
      segment {
        id
        tenant
        externalId
        name
        description
        query {
          qbQuery
          searchFilter
          sort {
            field
            direction
          }
        }
        filter {
          qbQuery
          searchFilter
          sort {
            field
            direction
          }
        }
        fieldsMappingId
        suppressionID
        suppression {
          id
          tenant
          name
          result
          activityLogQuerySuppressionEnabled
          activityLogQuerySuppression {
            query
            rawQuery
            threshold
          }
          pocQuerySuppressionEnabled
          pocQuerySuppression {
            query
            rawQuery
            suppressIfWeekendAndCantCallOnWeekends
          }
          restSuppressionEnabled
          restSuppression {
            endpoint
            method
            params {
              key
              value
              description
            }
            headers {
              key
              value
              description
            }
            auth {
              type
              info
            }
            response {
              path
              type
              value
              options
            }
            suppressOnError
            dispositionOnError
          }
          dncDotComEnabled
          dncDotCom {
            scrub
            state
            numberReassignment
            knownLitigator
            reassignmentSince
          }
          dncListSuppressionEnabled
          dncListSuppressions
          contactFieldSuppressionEnabled
          contactFieldSuppressions
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      campaign {
        id
        tenant
        name
        segments
        segmentSchedule
        campaignSchedule
        static
        sort
        skillId
        skillName
        skillSource
        campaignProfileId
        profile {
          id
          tenant
          name
          useAi
          flowRatio
          scrubber {
            cell
            natDnc
            stateDnc
            orgDnc
            phoneDup
            contactDup
          }
          retry {
            minDuration
            maxAttempts
            maxContacts
            custRetry
            ratio
          }
          ai {
            org
            system
          }
          pushContacts
          startTime
          endTime
          buffer
          channels {
            type
            order
            retryDelay
            maxAttempts
            maxContacts
            custRetry
            paymentProfileId
            paymentProfileName
            dispositions
            cadence
            dialingScript
          }
          ratio
          dontCallOnWeekends
          callWindow {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
          clickToCall
          callWindows {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
          dispositionScoring {
            noAnswer
            answer
            noAgentAvailableToDial
            noAgentOnAnswer
            overApproving
          }
          overrideDispositionScoring
          overrideDialToAgentRatio
          dialToAgentRatio
          freshLIFO
          retryLIFO
          createdAt
          updatedAt
        }
        status
        expireDate
        stopOnCompletion
        cci
        autoMode
        cciMetaData
        createdAt
        updatedAt
      }
      agent
      contactStatus
      timestamp
      metaData
      provider
      c2aId
      pocActual
      createdAt
      updatedAt
      contact {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        dnc
        updatedBy
        customFields
        expireDt
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        timeZoneBounds {
          min {
            offset
            dst
          }
          max {
            offset
            dst
          }
        }
        type
        optOuts {
          voice
          sms
          email
        }
        optIns {
          voice
          sms
          email
        }
        attempts
        createdAt
        updatedAt
      }
    }
  }
`;
export const listActivityLogs = /* GraphQL */ `
  query ListActivityLogs(
    $filter: ModelActivityLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivityLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        type
        disposition
        title
        body
        activityLogContactId
        segment {
          id
          tenant
          externalId
          name
          description
          query {
            qbQuery
            searchFilter
            sort {
              field
              direction
            }
          }
          filter {
            qbQuery
            searchFilter
            sort {
              field
              direction
            }
          }
          fieldsMappingId
          suppressionID
          suppression {
            id
            tenant
            name
            result
            activityLogQuerySuppressionEnabled
            activityLogQuerySuppression {
              query
              rawQuery
              threshold
            }
            pocQuerySuppressionEnabled
            pocQuerySuppression {
              query
              rawQuery
              suppressIfWeekendAndCantCallOnWeekends
            }
            restSuppressionEnabled
            restSuppression {
              endpoint
              method
              params {
                key
                value
                description
              }
              headers {
                key
                value
                description
              }
              auth {
                type
                info
              }
              response {
                path
                type
                value
                options
              }
              suppressOnError
              dispositionOnError
            }
            dncDotComEnabled
            dncDotCom {
              scrub
              state
              numberReassignment
              knownLitigator
              reassignmentSince
            }
            dncListSuppressionEnabled
            dncListSuppressions
            contactFieldSuppressionEnabled
            contactFieldSuppressions
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        campaign {
          id
          tenant
          name
          segments
          segmentSchedule
          campaignSchedule
          static
          sort
          skillId
          skillName
          skillSource
          campaignProfileId
          profile {
            id
            tenant
            name
            useAi
            flowRatio
            scrubber {
              cell
              natDnc
              stateDnc
              orgDnc
              phoneDup
              contactDup
            }
            retry {
              minDuration
              maxAttempts
              maxContacts
              custRetry
              ratio
            }
            ai {
              org
              system
            }
            pushContacts
            startTime
            endTime
            buffer
            channels {
              type
              order
              retryDelay
              maxAttempts
              maxContacts
              custRetry
              paymentProfileId
              paymentProfileName
              dispositions
              cadence
              dialingScript
            }
            ratio
            dontCallOnWeekends
            callWindow {
              days
              tmz {
                offset
                dst
              }
              beginTime {
                hour
                minute
              }
              endTime {
                hour
                minute
              }
              callWindowQuery {
                field
                operator
                value
              }
              default
              order
            }
            clickToCall
            callWindows {
              days
              tmz {
                offset
                dst
              }
              beginTime {
                hour
                minute
              }
              endTime {
                hour
                minute
              }
              callWindowQuery {
                field
                operator
                value
              }
              default
              order
            }
            dispositionScoring {
              noAnswer
              answer
              noAgentAvailableToDial
              noAgentOnAnswer
              overApproving
            }
            overrideDispositionScoring
            overrideDialToAgentRatio
            dialToAgentRatio
            freshLIFO
            retryLIFO
            createdAt
            updatedAt
          }
          status
          expireDate
          stopOnCompletion
          cci
          autoMode
          cciMetaData
          createdAt
          updatedAt
        }
        agent
        contactStatus
        timestamp
        metaData
        provider
        c2aId
        pocActual
        createdAt
        updatedAt
        contact {
          id
          tenant
          externalId
          firstName
          lastName
          source
          phone
          cell
          email
          dateCreated
          address1
          address2
          city
          state
          zip
          dnc
          updatedBy
          customFields
          expireDt
          outboundCallerId
          balances {
            id
            type
            amount
            age
          }
          timeZoneBounds {
            min {
              offset
              dst
            }
            max {
              offset
              dst
            }
          }
          type
          optOuts {
            voice
            sms
            email
          }
          optIns {
            voice
            sms
            email
          }
          attempts
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getContactActivity = /* GraphQL */ `
  query GetContactActivity(
    $activityLogContactId: ID
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getContactActivity(
      activityLogContactId: $activityLogContactId
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        type
        disposition
        title
        body
        activityLogContactId
        segment {
          id
          tenant
          externalId
          name
          description
          query {
            qbQuery
            searchFilter
            sort {
              field
              direction
            }
          }
          filter {
            qbQuery
            searchFilter
            sort {
              field
              direction
            }
          }
          fieldsMappingId
          suppressionID
          suppression {
            id
            tenant
            name
            result
            activityLogQuerySuppressionEnabled
            activityLogQuerySuppression {
              query
              rawQuery
              threshold
            }
            pocQuerySuppressionEnabled
            pocQuerySuppression {
              query
              rawQuery
              suppressIfWeekendAndCantCallOnWeekends
            }
            restSuppressionEnabled
            restSuppression {
              endpoint
              method
              params {
                key
                value
                description
              }
              headers {
                key
                value
                description
              }
              auth {
                type
                info
              }
              response {
                path
                type
                value
                options
              }
              suppressOnError
              dispositionOnError
            }
            dncDotComEnabled
            dncDotCom {
              scrub
              state
              numberReassignment
              knownLitigator
              reassignmentSince
            }
            dncListSuppressionEnabled
            dncListSuppressions
            contactFieldSuppressionEnabled
            contactFieldSuppressions
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        campaign {
          id
          tenant
          name
          segments
          segmentSchedule
          campaignSchedule
          static
          sort
          skillId
          skillName
          skillSource
          campaignProfileId
          profile {
            id
            tenant
            name
            useAi
            flowRatio
            scrubber {
              cell
              natDnc
              stateDnc
              orgDnc
              phoneDup
              contactDup
            }
            retry {
              minDuration
              maxAttempts
              maxContacts
              custRetry
              ratio
            }
            ai {
              org
              system
            }
            pushContacts
            startTime
            endTime
            buffer
            channels {
              type
              order
              retryDelay
              maxAttempts
              maxContacts
              custRetry
              paymentProfileId
              paymentProfileName
              dispositions
              cadence
              dialingScript
            }
            ratio
            dontCallOnWeekends
            callWindow {
              days
              tmz {
                offset
                dst
              }
              beginTime {
                hour
                minute
              }
              endTime {
                hour
                minute
              }
              callWindowQuery {
                field
                operator
                value
              }
              default
              order
            }
            clickToCall
            callWindows {
              days
              tmz {
                offset
                dst
              }
              beginTime {
                hour
                minute
              }
              endTime {
                hour
                minute
              }
              callWindowQuery {
                field
                operator
                value
              }
              default
              order
            }
            dispositionScoring {
              noAnswer
              answer
              noAgentAvailableToDial
              noAgentOnAnswer
              overApproving
            }
            overrideDispositionScoring
            overrideDialToAgentRatio
            dialToAgentRatio
            freshLIFO
            retryLIFO
            createdAt
            updatedAt
          }
          status
          expireDate
          stopOnCompletion
          cci
          autoMode
          cciMetaData
          createdAt
          updatedAt
        }
        agent
        contactStatus
        timestamp
        metaData
        provider
        c2aId
        pocActual
        createdAt
        updatedAt
        contact {
          id
          tenant
          externalId
          firstName
          lastName
          source
          phone
          cell
          email
          dateCreated
          address1
          address2
          city
          state
          zip
          dnc
          updatedBy
          customFields
          expireDt
          outboundCallerId
          balances {
            id
            type
            amount
            age
          }
          timeZoneBounds {
            min {
              offset
              dst
            }
            max {
              offset
              dst
            }
          }
          type
          optOuts {
            voice
            sms
            email
          }
          optIns {
            voice
            sms
            email
          }
          attempts
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTenantActivity = /* GraphQL */ `
  query GetTenantActivity(
    $tenant: ID
    $typeContactStatusTimestamp: ModelActivityLogTenantActivityCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTenantActivity(
      tenant: $tenant
      typeContactStatusTimestamp: $typeContactStatusTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        type
        disposition
        title
        body
        activityLogContactId
        segment {
          id
          tenant
          externalId
          name
          description
          query {
            qbQuery
            searchFilter
            sort {
              field
              direction
            }
          }
          filter {
            qbQuery
            searchFilter
            sort {
              field
              direction
            }
          }
          fieldsMappingId
          suppressionID
          suppression {
            id
            tenant
            name
            result
            activityLogQuerySuppressionEnabled
            activityLogQuerySuppression {
              query
              rawQuery
              threshold
            }
            pocQuerySuppressionEnabled
            pocQuerySuppression {
              query
              rawQuery
              suppressIfWeekendAndCantCallOnWeekends
            }
            restSuppressionEnabled
            restSuppression {
              endpoint
              method
              params {
                key
                value
                description
              }
              headers {
                key
                value
                description
              }
              auth {
                type
                info
              }
              response {
                path
                type
                value
                options
              }
              suppressOnError
              dispositionOnError
            }
            dncDotComEnabled
            dncDotCom {
              scrub
              state
              numberReassignment
              knownLitigator
              reassignmentSince
            }
            dncListSuppressionEnabled
            dncListSuppressions
            contactFieldSuppressionEnabled
            contactFieldSuppressions
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        campaign {
          id
          tenant
          name
          segments
          segmentSchedule
          campaignSchedule
          static
          sort
          skillId
          skillName
          skillSource
          campaignProfileId
          profile {
            id
            tenant
            name
            useAi
            flowRatio
            scrubber {
              cell
              natDnc
              stateDnc
              orgDnc
              phoneDup
              contactDup
            }
            retry {
              minDuration
              maxAttempts
              maxContacts
              custRetry
              ratio
            }
            ai {
              org
              system
            }
            pushContacts
            startTime
            endTime
            buffer
            channels {
              type
              order
              retryDelay
              maxAttempts
              maxContacts
              custRetry
              paymentProfileId
              paymentProfileName
              dispositions
              cadence
              dialingScript
            }
            ratio
            dontCallOnWeekends
            callWindow {
              days
              tmz {
                offset
                dst
              }
              beginTime {
                hour
                minute
              }
              endTime {
                hour
                minute
              }
              callWindowQuery {
                field
                operator
                value
              }
              default
              order
            }
            clickToCall
            callWindows {
              days
              tmz {
                offset
                dst
              }
              beginTime {
                hour
                minute
              }
              endTime {
                hour
                minute
              }
              callWindowQuery {
                field
                operator
                value
              }
              default
              order
            }
            dispositionScoring {
              noAnswer
              answer
              noAgentAvailableToDial
              noAgentOnAnswer
              overApproving
            }
            overrideDispositionScoring
            overrideDialToAgentRatio
            dialToAgentRatio
            freshLIFO
            retryLIFO
            createdAt
            updatedAt
          }
          status
          expireDate
          stopOnCompletion
          cci
          autoMode
          cciMetaData
          createdAt
          updatedAt
        }
        agent
        contactStatus
        timestamp
        metaData
        provider
        c2aId
        pocActual
        createdAt
        updatedAt
        contact {
          id
          tenant
          externalId
          firstName
          lastName
          source
          phone
          cell
          email
          dateCreated
          address1
          address2
          city
          state
          zip
          dnc
          updatedBy
          customFields
          expireDt
          outboundCallerId
          balances {
            id
            type
            amount
            age
          }
          timeZoneBounds {
            min {
              offset
              dst
            }
            max {
              offset
              dst
            }
          }
          type
          optOuts {
            voice
            sms
            email
          }
          optIns {
            voice
            sms
            email
          }
          attempts
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTenantTimestamp = /* GraphQL */ `
  query GetTenantTimestamp(
    $tenant: ID
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTenantTimestamp(
      tenant: $tenant
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        type
        disposition
        title
        body
        activityLogContactId
        segment {
          id
          tenant
          externalId
          name
          description
          query {
            qbQuery
            searchFilter
            sort {
              field
              direction
            }
          }
          filter {
            qbQuery
            searchFilter
            sort {
              field
              direction
            }
          }
          fieldsMappingId
          suppressionID
          suppression {
            id
            tenant
            name
            result
            activityLogQuerySuppressionEnabled
            activityLogQuerySuppression {
              query
              rawQuery
              threshold
            }
            pocQuerySuppressionEnabled
            pocQuerySuppression {
              query
              rawQuery
              suppressIfWeekendAndCantCallOnWeekends
            }
            restSuppressionEnabled
            restSuppression {
              endpoint
              method
              params {
                key
                value
                description
              }
              headers {
                key
                value
                description
              }
              auth {
                type
                info
              }
              response {
                path
                type
                value
                options
              }
              suppressOnError
              dispositionOnError
            }
            dncDotComEnabled
            dncDotCom {
              scrub
              state
              numberReassignment
              knownLitigator
              reassignmentSince
            }
            dncListSuppressionEnabled
            dncListSuppressions
            contactFieldSuppressionEnabled
            contactFieldSuppressions
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        campaign {
          id
          tenant
          name
          segments
          segmentSchedule
          campaignSchedule
          static
          sort
          skillId
          skillName
          skillSource
          campaignProfileId
          profile {
            id
            tenant
            name
            useAi
            flowRatio
            scrubber {
              cell
              natDnc
              stateDnc
              orgDnc
              phoneDup
              contactDup
            }
            retry {
              minDuration
              maxAttempts
              maxContacts
              custRetry
              ratio
            }
            ai {
              org
              system
            }
            pushContacts
            startTime
            endTime
            buffer
            channels {
              type
              order
              retryDelay
              maxAttempts
              maxContacts
              custRetry
              paymentProfileId
              paymentProfileName
              dispositions
              cadence
              dialingScript
            }
            ratio
            dontCallOnWeekends
            callWindow {
              days
              tmz {
                offset
                dst
              }
              beginTime {
                hour
                minute
              }
              endTime {
                hour
                minute
              }
              callWindowQuery {
                field
                operator
                value
              }
              default
              order
            }
            clickToCall
            callWindows {
              days
              tmz {
                offset
                dst
              }
              beginTime {
                hour
                minute
              }
              endTime {
                hour
                minute
              }
              callWindowQuery {
                field
                operator
                value
              }
              default
              order
            }
            dispositionScoring {
              noAnswer
              answer
              noAgentAvailableToDial
              noAgentOnAnswer
              overApproving
            }
            overrideDispositionScoring
            overrideDialToAgentRatio
            dialToAgentRatio
            freshLIFO
            retryLIFO
            createdAt
            updatedAt
          }
          status
          expireDate
          stopOnCompletion
          cci
          autoMode
          cciMetaData
          createdAt
          updatedAt
        }
        agent
        contactStatus
        timestamp
        metaData
        provider
        c2aId
        pocActual
        createdAt
        updatedAt
        contact {
          id
          tenant
          externalId
          firstName
          lastName
          source
          phone
          cell
          email
          dateCreated
          address1
          address2
          city
          state
          zip
          dnc
          updatedBy
          customFields
          expireDt
          outboundCallerId
          balances {
            id
            type
            amount
            age
          }
          timeZoneBounds {
            min {
              offset
              dst
            }
            max {
              offset
              dst
            }
          }
          type
          optOuts {
            voice
            sms
            email
          }
          optIns {
            voice
            sms
            email
          }
          attempts
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchActivityLogs = /* GraphQL */ `
  query SearchActivityLogs(
    $filter: SearchableActivityLogFilterInput
    $sort: SearchableActivityLogSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchActivityLogs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        tenant
        type
        disposition
        title
        body
        activityLogContactId
        segment {
          id
          tenant
          externalId
          name
          description
          query {
            qbQuery
            searchFilter
            sort {
              field
              direction
            }
          }
          filter {
            qbQuery
            searchFilter
            sort {
              field
              direction
            }
          }
          fieldsMappingId
          suppressionID
          suppression {
            id
            tenant
            name
            result
            activityLogQuerySuppressionEnabled
            activityLogQuerySuppression {
              query
              rawQuery
              threshold
            }
            pocQuerySuppressionEnabled
            pocQuerySuppression {
              query
              rawQuery
              suppressIfWeekendAndCantCallOnWeekends
            }
            restSuppressionEnabled
            restSuppression {
              endpoint
              method
              params {
                key
                value
                description
              }
              headers {
                key
                value
                description
              }
              auth {
                type
                info
              }
              response {
                path
                type
                value
                options
              }
              suppressOnError
              dispositionOnError
            }
            dncDotComEnabled
            dncDotCom {
              scrub
              state
              numberReassignment
              knownLitigator
              reassignmentSince
            }
            dncListSuppressionEnabled
            dncListSuppressions
            contactFieldSuppressionEnabled
            contactFieldSuppressions
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        campaign {
          id
          tenant
          name
          segments
          segmentSchedule
          campaignSchedule
          static
          sort
          skillId
          skillName
          skillSource
          campaignProfileId
          profile {
            id
            tenant
            name
            useAi
            flowRatio
            scrubber {
              cell
              natDnc
              stateDnc
              orgDnc
              phoneDup
              contactDup
            }
            retry {
              minDuration
              maxAttempts
              maxContacts
              custRetry
              ratio
            }
            ai {
              org
              system
            }
            pushContacts
            startTime
            endTime
            buffer
            channels {
              type
              order
              retryDelay
              maxAttempts
              maxContacts
              custRetry
              paymentProfileId
              paymentProfileName
              dispositions
              cadence
              dialingScript
            }
            ratio
            dontCallOnWeekends
            callWindow {
              days
              tmz {
                offset
                dst
              }
              beginTime {
                hour
                minute
              }
              endTime {
                hour
                minute
              }
              callWindowQuery {
                field
                operator
                value
              }
              default
              order
            }
            clickToCall
            callWindows {
              days
              tmz {
                offset
                dst
              }
              beginTime {
                hour
                minute
              }
              endTime {
                hour
                minute
              }
              callWindowQuery {
                field
                operator
                value
              }
              default
              order
            }
            dispositionScoring {
              noAnswer
              answer
              noAgentAvailableToDial
              noAgentOnAnswer
              overApproving
            }
            overrideDispositionScoring
            overrideDialToAgentRatio
            dialToAgentRatio
            freshLIFO
            retryLIFO
            createdAt
            updatedAt
          }
          status
          expireDate
          stopOnCompletion
          cci
          autoMode
          cciMetaData
          createdAt
          updatedAt
        }
        agent
        contactStatus
        timestamp
        metaData
        provider
        c2aId
        pocActual
        createdAt
        updatedAt
        contact {
          id
          tenant
          externalId
          firstName
          lastName
          source
          phone
          cell
          email
          dateCreated
          address1
          address2
          city
          state
          zip
          dnc
          updatedBy
          customFields
          expireDt
          outboundCallerId
          balances {
            id
            type
            amount
            age
          }
          timeZoneBounds {
            min {
              offset
              dst
            }
            max {
              offset
              dst
            }
          }
          type
          optOuts {
            voice
            sms
            email
          }
          optIns {
            voice
            sms
            email
          }
          attempts
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const listAgentScripts = /* GraphQL */ `
  query ListAgentScripts(
    $filter: ModelAgentScriptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgentScripts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        name
        level
        type
        textBody
        isRoot
        createdAt
        updatedAt
        children {
          items {
            id
            parentId
            childId
            createdAt
            updatedAt
            parent {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
            child {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAgentScript = /* GraphQL */ `
  query GetAgentScript($id: ID!) {
    getAgentScript(id: $id) {
      id
      tenant
      name
      level
      type
      textBody
      isRoot
      createdAt
      updatedAt
      children {
        items {
          id
          parentId
          childId
          createdAt
          updatedAt
          parent {
            id
            tenant
            name
            level
            type
            textBody
            isRoot
            createdAt
            updatedAt
            children {
              items {
                id
                parentId
                childId
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          child {
            id
            tenant
            name
            level
            type
            textBody
            isRoot
            createdAt
            updatedAt
            children {
              items {
                id
                parentId
                childId
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const agentScriptByTenant = /* GraphQL */ `
  query AgentScriptByTenant(
    $tenant: ID
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAgentScriptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    agentScriptByTenant(
      tenant: $tenant
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        name
        level
        type
        textBody
        isRoot
        createdAt
        updatedAt
        children {
          items {
            id
            parentId
            childId
            createdAt
            updatedAt
            parent {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
            child {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchAgentScripts = /* GraphQL */ `
  query SearchAgentScripts(
    $filter: SearchableAgentScriptFilterInput
    $sort: SearchableAgentScriptSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchAgentScripts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        tenant
        name
        level
        type
        textBody
        isRoot
        createdAt
        updatedAt
        children {
          items {
            id
            parentId
            childId
            createdAt
            updatedAt
            parent {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
            child {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getAgentScriptConnector = /* GraphQL */ `
  query GetAgentScriptConnector($id: ID!) {
    getAgentScriptConnector(id: $id) {
      id
      parentId
      childId
      createdAt
      updatedAt
      parent {
        id
        tenant
        name
        level
        type
        textBody
        isRoot
        createdAt
        updatedAt
        children {
          items {
            id
            parentId
            childId
            createdAt
            updatedAt
            parent {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
            child {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
          }
          nextToken
        }
      }
      child {
        id
        tenant
        name
        level
        type
        textBody
        isRoot
        createdAt
        updatedAt
        children {
          items {
            id
            parentId
            childId
            createdAt
            updatedAt
            parent {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
            child {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listAgentScriptConnectors = /* GraphQL */ `
  query ListAgentScriptConnectors(
    $filter: ModelAgentScriptConnectorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgentScriptConnectors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentId
        childId
        createdAt
        updatedAt
        parent {
          id
          tenant
          name
          level
          type
          textBody
          isRoot
          createdAt
          updatedAt
          children {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              parent {
                id
                tenant
                name
                level
                type
                textBody
                isRoot
                createdAt
                updatedAt
              }
              child {
                id
                tenant
                name
                level
                type
                textBody
                isRoot
                createdAt
                updatedAt
              }
            }
            nextToken
          }
        }
        child {
          id
          tenant
          name
          level
          type
          textBody
          isRoot
          createdAt
          updatedAt
          children {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              parent {
                id
                tenant
                name
                level
                type
                textBody
                isRoot
                createdAt
                updatedAt
              }
              child {
                id
                tenant
                name
                level
                type
                textBody
                isRoot
                createdAt
                updatedAt
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getCryptoPassport = /* GraphQL */ `
  query GetCryptoPassport($id: ID!) {
    getCryptoPassport(id: $id) {
      id
      token
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const listCryptoPassports = /* GraphQL */ `
  query ListCryptoPassports(
    $filter: ModelCryptoPassportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCryptoPassports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        token
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContactFieldMeta = /* GraphQL */ `
  query GetContactFieldMeta($id: ID!) {
    getContactFieldMeta(id: $id) {
      id
      tenant
      contact
      field
      type
      poc {
        cell
        consent
        writtenConsent
        optOut
        preferred
        thirdParty
        dontCallOnWeekends
        callWindows {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
      }
      updatedBy
      createdAt
      updatedAt
      contactModel {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        dnc
        updatedBy
        customFields
        expireDt
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        timeZoneBounds {
          min {
            offset
            dst
          }
          max {
            offset
            dst
          }
        }
        type
        optOuts {
          voice
          sms
          email
        }
        optIns {
          voice
          sms
          email
        }
        attempts
        createdAt
        updatedAt
      }
    }
  }
`;
export const listContactFieldMetas = /* GraphQL */ `
  query ListContactFieldMetas(
    $filter: ModelContactFieldMetaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactFieldMetas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        contact
        field
        type
        poc {
          cell
          consent
          writtenConsent
          optOut
          preferred
          thirdParty
          dontCallOnWeekends
          callWindows {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
        }
        updatedBy
        createdAt
        updatedAt
        contactModel {
          id
          tenant
          externalId
          firstName
          lastName
          source
          phone
          cell
          email
          dateCreated
          address1
          address2
          city
          state
          zip
          dnc
          updatedBy
          customFields
          expireDt
          outboundCallerId
          balances {
            id
            type
            amount
            age
          }
          timeZoneBounds {
            min {
              offset
              dst
            }
            max {
              offset
              dst
            }
          }
          type
          optOuts {
            voice
            sms
            email
          }
          optIns {
            voice
            sms
            email
          }
          attempts
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const pocMeta = /* GraphQL */ `
  query PocMeta(
    $tenant: ID
    $contactField: ModelContactFieldMetaPocMetaCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactFieldMetaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PocMeta(
      tenant: $tenant
      contactField: $contactField
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        contact
        field
        type
        poc {
          cell
          consent
          writtenConsent
          optOut
          preferred
          thirdParty
          dontCallOnWeekends
          callWindows {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
        }
        updatedBy
        createdAt
        updatedAt
        contactModel {
          id
          tenant
          externalId
          firstName
          lastName
          source
          phone
          cell
          email
          dateCreated
          address1
          address2
          city
          state
          zip
          dnc
          updatedBy
          customFields
          expireDt
          outboundCallerId
          balances {
            id
            type
            amount
            age
          }
          timeZoneBounds {
            min {
              offset
              dst
            }
            max {
              offset
              dst
            }
          }
          type
          optOuts {
            voice
            sms
            email
          }
          optIns {
            voice
            sms
            email
          }
          attempts
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const tenantContact = /* GraphQL */ `
  query TenantContact(
    $tenant: ID
    $contact: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactFieldMetaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantContact(
      tenant: $tenant
      contact: $contact
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        contact
        field
        type
        poc {
          cell
          consent
          writtenConsent
          optOut
          preferred
          thirdParty
          dontCallOnWeekends
          callWindows {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
        }
        updatedBy
        createdAt
        updatedAt
        contactModel {
          id
          tenant
          externalId
          firstName
          lastName
          source
          phone
          cell
          email
          dateCreated
          address1
          address2
          city
          state
          zip
          dnc
          updatedBy
          customFields
          expireDt
          outboundCallerId
          balances {
            id
            type
            amount
            age
          }
          timeZoneBounds {
            min {
              offset
              dst
            }
            max {
              offset
              dst
            }
          }
          type
          optOuts {
            voice
            sms
            email
          }
          optIns {
            voice
            sms
            email
          }
          attempts
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const tenantField = /* GraphQL */ `
  query TenantField(
    $tenant: ID
    $field: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactFieldMetaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantField(
      tenant: $tenant
      field: $field
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        contact
        field
        type
        poc {
          cell
          consent
          writtenConsent
          optOut
          preferred
          thirdParty
          dontCallOnWeekends
          callWindows {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
        }
        updatedBy
        createdAt
        updatedAt
        contactModel {
          id
          tenant
          externalId
          firstName
          lastName
          source
          phone
          cell
          email
          dateCreated
          address1
          address2
          city
          state
          zip
          dnc
          updatedBy
          customFields
          expireDt
          outboundCallerId
          balances {
            id
            type
            amount
            age
          }
          timeZoneBounds {
            min {
              offset
              dst
            }
            max {
              offset
              dst
            }
          }
          type
          optOuts {
            voice
            sms
            email
          }
          optIns {
            voice
            sms
            email
          }
          attempts
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchContactFieldMetas = /* GraphQL */ `
  query SearchContactFieldMetas(
    $filter: SearchableContactFieldMetaFilterInput
    $sort: SearchableContactFieldMetaSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchContactFieldMetas(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        tenant
        contact
        field
        type
        poc {
          cell
          consent
          writtenConsent
          optOut
          preferred
          thirdParty
          dontCallOnWeekends
          callWindows {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
        }
        updatedBy
        createdAt
        updatedAt
        contactModel {
          id
          tenant
          externalId
          firstName
          lastName
          source
          phone
          cell
          email
          dateCreated
          address1
          address2
          city
          state
          zip
          dnc
          updatedBy
          customFields
          expireDt
          outboundCallerId
          balances {
            id
            type
            amount
            age
          }
          timeZoneBounds {
            min {
              offset
              dst
            }
            max {
              offset
              dst
            }
          }
          type
          optOuts {
            voice
            sms
            email
          }
          optIns {
            voice
            sms
            email
          }
          attempts
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getDailyLicenseCount = /* GraphQL */ `
  query GetDailyLicenseCount($id: ID!) {
    getDailyLicenseCount(id: $id) {
      id
      ownerTenant
      tenant
      licenseType
      ncBusinessUnit
      Date
      LicenseCount
      SmsCount
      EmailCount
      createdAt
      updatedAt
    }
  }
`;
export const listDailyLicenseCounts = /* GraphQL */ `
  query ListDailyLicenseCounts(
    $filter: ModelDailyLicenseCountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDailyLicenseCounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerTenant
        tenant
        licenseType
        ncBusinessUnit
        Date
        LicenseCount
        SmsCount
        EmailCount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchDailyLicenseCounts = /* GraphQL */ `
  query SearchDailyLicenseCounts(
    $filter: SearchableDailyLicenseCountFilterInput
    $sort: SearchableDailyLicenseCountSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchDailyLicenseCounts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        ownerTenant
        tenant
        licenseType
        ncBusinessUnit
        Date
        LicenseCount
        SmsCount
        EmailCount
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
