import { TextField, FormControl, Select, CardContent, Card, CardHeader, MenuItem, Checkbox, InputLabel, Input, ListItemText, FormHelperText, Box, Tooltip, Typography } from '@mui/material';
import { UserContext } from "src/contexts/UserContext";
import React, { useContext } from "react";


export default function Details(props) {
	const { formikProps, skills } = props;

	const userContext = useContext(UserContext);
	//console.log(userContext)

	return (
		<Card variant='outlined'>
			<CardHeader title='Details' titleTypographyProps={{
				variant: "body2",
				color: "textSecondary",
			}} />
			<CardContent>
				<Box display="grid" gridTemplateColumns="1fr" gap={2}>
					<TextField
						margin="dense"
						name="name"
						label="Name"
						type="text"
						required={true}
						value={formikProps.values.name}
						onChange={formikProps.handleChange}
						onBlur={formikProps.handleBlur}
						error={formikProps.errors.name && formikProps.touched.name}
						helperText={formikProps.touched.name && formikProps.errors.name}
					/>
					<TextField
						margin="dense"
						name="vendorId"
						label="Vendor Id"
						type="text"
						required={true}
						value={formikProps.values.vendorId}
						onChange={formikProps.handleChange}
						onBlur={formikProps.handleBlur}
						error={formikProps.errors.vendorId && formikProps.touched.vendorId}
						helperText={formikProps.touched.vendorId && formikProps.errors.vendorId}
					/>
					<TextField
						margin="dense"
						name="apiKey"
						label="Api Key"
						type="text"
						required={true}
						value={formikProps.values.apiKey}
						onChange={formikProps.handleChange}
						onBlur={formikProps.handleBlur}
						error={formikProps.errors.apiKey && formikProps.touched.apiKey}
						helperText={formikProps.touched.apiKey ? formikProps.errors.apiKey : ""}
					/>
					<TextField
						margin="dense"
						name="ttl"
						label={
							<Tooltip title="How long the message is valid before triggering retry delay for next contact attempt.">
								<Typography variant="body1">Expiration Time</Typography>
							</Tooltip>
						}
						type="number"
						value={formikProps.values.ttl}
						onChange={formikProps.handleChange}
						onBlur={formikProps.handleBlur}
						error={formikProps.errors.ttl && formikProps.touched.ttl}
						helperText={formikProps.touched.ttl && formikProps.errors.ttl}
						inputProps={{
							min: 1
						}}
					/>
					<FormControl>
						<InputLabel>Messaging Type</InputLabel>
						<Select
							label="Messaging Type"
							name='messagingType'
							value={props.messageType}
							onChange={(e) => props.setMessageType(e.target.value)}
							>
							<MenuItem value='message-only'>Messaging Only</MenuItem>
							<MenuItem value='action-message'>Messaging w/ C2A</MenuItem>
							</Select>
					</FormControl>
					{/* {userContext.telephonyProvider === "NiC" &&
						<FormControl>
							<InputLabel error={formikProps.errors.promptPaySkills && formikProps.touched.promptPaySkills}>C2A Skills</InputLabel>
							<Select
								multiple
								label="C2A Skills"
								name='promptPaySkills'
								value={formikProps.values.promptPaySkills}
								onChange={formikProps.handleChange}
								renderValue={(selected) => selected.join(', ')}
								error={formikProps.errors.promptPaySkills && formikProps.touched.promptPaySkills}
							>
								{skills.map(skill =>
									<MenuItem key={skill.skillId} value={`${skill.skillId}`}>
										<Checkbox checked={formikProps.values.promptPaySkills.indexOf(`${skill.skillId}`) > -1} color="primary" />
										<ListItemText primary={skill.skillName} />
									</MenuItem>)}
							</Select>
							{formikProps.touched.promptPaySkills && formikProps.errors.promptPaySkills &&
								<FormHelperText error>{formikProps.errors.promptPaySkills}</FormHelperText>}
						</FormControl>} */}
				</Box>
			</CardContent>
		</Card>
	);
}
